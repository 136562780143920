import React from "react"
import { graphql, navigate } from "gatsby"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import BannerSection from "../../components/Global/TopBanner"
import ServiceIntro from "../../components/Service/ServiceIntro"
import SectionRow from "../../components/Service/SectionRow"
import Toolbox from "../../components/Service/Toolbox"
import ToolboxModal from "../../components/Service/ToolboxModal"
import NavRow from "../../components/Service/NavRow"
import { SERVICE, INIT_CONTENT } from "../../components/Service/data"

const SericesPage = ({ data, location }) => {
  const serviceData = SERVICE.ecommerce
  const [modalVisible, showModal] = React.useState(false)
  const [modalContent, setModalContent] = React.useState(INIT_CONTENT)

  React.useEffect(() => {
    replacePageLink()

    window.addEventListener("resize", handleResizeModal)
    return () => {
      window.removeEventListener("resize", handleResizeModal)
    }
  }, [])

  const replacePageLink = () => {
    const intro = document.getElementsByClassName("service-subtitle")
    const introText = intro[0]

    introText.innerHTML = introText.innerHTML
      .replace(/WordPress/g, '<a href="/services/wordpress">WordPress</a>')
      .replace(/Shopify/g, '<a href="/services/shopify">Shopify</a>')
  }

  const handleResizeModal = () => {
    if (window.innerWidth >= 350) {
      showModal(false)
    }
  }

  const navigateToDetail = (name) => {
    let routeName = `/services/${name}`

    navigate(routeName)
  }

  const handleToolboxClick = (tool) => {
    if (tool && window.innerWidth < 350) {
      showModal(true)
      setModalContent({ desc: tool.desc, icon: tool.icon })
    } else {
      if (tool.icon === "woocommerce") {
        navigateToDetail("wordpress")
      }
      if (tool.icon === "shopify") {
        navigateToDetail("shopify")
      }
    }
  }

  return (
    <Layout location={location}>
      <SEO title={serviceData.title} keywords={[`services`, `ecommerce`]} />
      <BannerSection
        img={data.img.childImageSharp.fluid}
        title="Our Services"
      />

      <NavRow pathname={location.pathname} />
      <ServiceIntro title={serviceData.title} subtitle={serviceData.subtitle} />

      {serviceData.sections.map((s, i) => (
        <SectionRow
          key={s.header}
          header={s.header}
          content={s.content}
          iconPosition={i % 2 === 0 ? "right" : "left"}
        />
      ))}

      <div className="modal-container">
        <Toolbox data={serviceData.tools} onViewTool={handleToolboxClick} />

        <ToolboxModal
          visible={modalVisible}
          content={modalContent}
          onModalClose={() => showModal(false)}
        />
      </div>
    </Layout>
  )
}

export const query = graphql`
  {
    img: file(relativePath: { eq: "service-banner.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1920, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
  }
`
export default SericesPage
